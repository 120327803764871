import {Component, Input, EventEmitter, Output, ViewChild} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import { Subscription } from 'rxjs';

import { GlobalsProvider } from '../../providers/globals.provider';
import { NetworkProvider } from '../../providers/network.provider';

import { loadOpacity } from '../../animations/index';
import {ModalComponent} from '../modal/modal.component';

declare const $: any;
declare const require: any;
var moment = require('moment');

@Component({
  selector: 'measurements-graphedit',
  templateUrl: './measurements-graphedit.component.html',
  styleUrls: ['./measurements-graphedit.component.css'],
  animations: [loadOpacity()]
})
export class MeasurementsGrapheditComponent {
  @Output() finished: EventEmitter<any> = new EventEmitter<any>();
  @Input('graph_id') graph_id: any;
  @Input('additionalRegisters') additionalRegisters: any;
  @ViewChild('editregisterModal', { static: false }) public editregisterModal: ModalComponent;

  componentState: String = "init";
  public myUser: any;
  private subscriptions: Subscription = new Subscription();
  paramsSub: any;
  public formData: FormGroup;
  public submitted: boolean;
  public devices: any;
  public deviceRegisters: any = [];
  public currentRegister: any;
  public graph: any;
  public projects: any;
  public licenses: any;
  public measurement_units: any;
  public timeMode: any = 'all';
  public aggregationMode: any = 'none';
  public aggregationKind: any = 'sum';
  public projectSelection: any = [];
  public registerSelection: any = [];
  public registerToEdit: any;
  public graphKinds: any;

  constructor(private router: Router, private route: ActivatedRoute, private globals:GlobalsProvider, public network:NetworkProvider, public _fb: FormBuilder) {
    this.myUser = this.globals.getData("user");

    this.subscriptions.add(this.globals.addObservable('user').subscribe(
      data => {
        this.myUser = data;
      }
    ));

    this.graphKinds = this.globals.graphKinds;
  }

  ngOnInit() {
    this.loadOptions();
  }

  loadOptions() {
    this.network.sendRequest('/measurements/graphs/options', 'GET').then((data:any) => {
      this.projects = data.projects;
      this.licenses = data.licenses;
      this.devices = data.devices;
      this.measurement_units = data.measurement_units;

      if(this.devices && this.devices.length > 0) {
        for(let i = 0; i < this.devices.length; i++) {
          if(this.devices[i].DeviceRegisters && this.devices[i].DeviceRegisters.length > 0) {
            for(let j = 0; j < this.devices[i].DeviceRegisters.length; j++) {
              let deviceRegisterToAdd = {
                id: this.devices[i].DeviceRegisters[j].id,
                device_id: this.devices[i].DeviceRegisters[j].device_id,
                project_id: this.devices[i].DeviceRegisters[j].project_id,
                measurement_unit_id: null,
                name: this.devices[i].name + "-> ["+this.devices[i].DeviceRegisters[j].token+"] " + this.devices[i].DeviceRegisters[j].name,
                color: '#000000'
              };

              if(this.devices[i].DeviceRegisters[j].MeasurementUnit) {
                if(this.devices[i].DeviceRegisters[j].MeasurementUnit.color_graph) {
                  deviceRegisterToAdd.color = this.devices[i].DeviceRegisters[j].MeasurementUnit.color_graph;
                }
                deviceRegisterToAdd.measurement_unit_id = this.devices[i].DeviceRegisters[j].MeasurementUnit.id;
              }

              this.deviceRegisters.push(deviceRegisterToAdd);
            }
          }
        }
      }

      if(this.additionalRegisters && this.additionalRegisters.length > 0) {
        for(let i = 0; i < this.additionalRegisters.length; i++) {
          if(this.additionalRegisters[i].color) {
          } else if(this.additionalRegisters[i].GraphDeviceRegister && this.additionalRegisters[i].GraphDeviceRegister.color && this.additionalRegisters[i].GraphDeviceRegister.color != "#ffffff") {
            this.additionalRegisters[i].color = this.additionalRegisters[i].GraphDeviceRegister.color;
          } else if(this.additionalRegisters[i].MeasurementUnit.color_graph) {
            this.additionalRegisters[i].color = this.additionalRegisters[i].MeasurementUnit.color_graph;
          }
        }
      }

      this.loadData();
    }).catch((e: any) => {
      console.log(e);
      this.finished.emit(false);
    });
  }


  loadData() {
    this.network.sendRequest('/measurements/graphs/list', 'POST', {where: {id: this.graph_id}}).then((data:any) => {
      this.graph = data.graphs[0];

      if(this.graph.DeviceRegisters && this.graph.DeviceRegisters.length > 0) {
        for(let j = 0; j < this.graph.DeviceRegisters.length; j++) {
          if(this.graph.DeviceRegisters[j].GraphDeviceRegister && this.graph.DeviceRegisters[j].GraphDeviceRegister.color) {
            this.graph.DeviceRegisters[j].color = this.graph.DeviceRegisters[j].GraphDeviceRegister.color;
          } else if(this.graph.DeviceRegisters[j].MeasurementUnit && this.graph.DeviceRegisters[j].MeasurementUnit.color_graph) {
            this.graph.DeviceRegisters[j].color = this.graph.DeviceRegisters[j].MeasurementUnit.color_graph;
          } else {
            this.graph.DeviceRegisters[j].color = "#000000";
          }

          if(this.graph.DeviceRegisters[j].GraphDeviceRegister && this.graph.DeviceRegisters[j].GraphDeviceRegister.measurement_unit_id) {
            this.graph.DeviceRegisters[j].measurement_unit_id = this.graph.DeviceRegisters[j].GraphDeviceRegister.measurement_unit_id;
          } else if(this.graph.DeviceRegisters[j].MeasurementUnit) {
            this.graph.DeviceRegisters[j].measurement_unit_id = this.graph.DeviceRegisters[j].MeasurementUnit.id;
          }
        }
      }

      this.setupForm();
    }).catch((e: any) => {
      console.log(e);
      this.finished.emit(false);
    });
  }

  markDefaultRegisters() {
      if(this.graph.DeviceRegisters && this.graph.DeviceRegisters.length > 0) {
        for(let i = 0; i < this.graph.DeviceRegisters.length; i++) {
          for(let j = 0; j < this.deviceRegisters.length; j++) {
            if(this.deviceRegisters[j].id == this.graph.DeviceRegisters[i].id) {
              this.deviceRegisters[j].marked = true;
              this.deviceRegisters[j].color = this.graph.DeviceRegisters[i].color;
              this.deviceRegisters[j].measurement_unit_id = this.graph.DeviceRegisters[i].measurement_unit_id;
              break;
            }
          }
        }
      }
  }

  onCurrentRegisterSelect(id: any) {
    for(let i = 0; i < this.deviceRegisters.length; i++) {
      if(this.deviceRegisters[i].id == id) {
        this.currentRegister = this.deviceRegisters[i];
        break;
      }
    }
  }

  onAddCurrentRegister() {
    if(!this.currentRegister) {
      return;
    }

    for(let i = 0; i < this.deviceRegisters.length; i++) {
      if(this.deviceRegisters[i].id == this.currentRegister.id) {
        this.deviceRegisters[i].marked = true;
        break;
      }
    }
  }

  onRemoveRegisterMark(id: any) {
    for(let i = 0; i < this.deviceRegisters.length; i++) {
      if(this.deviceRegisters[i].id == id) {
        this.deviceRegisters[i].marked = false;
        break;
      }
    }
  }

  setupForm() {
    this.timeMode = this.graph.timemode ? this.graph.timemode : 'all';
    this.aggregationMode = this.graph.aggregationmode ? this.graph.aggregationmode : 'none';
    this.aggregationKind = this.graph.aggregationkind ? this.graph.aggregationkind : 'sum';
    let date_to = moment(this.graph.date_to).format('DD.MM.YYYY');
    let date_from = moment(this.graph.date_from).format('DD.MM.YYYY');

    this.onSelectLicense(this.graph.license_id);
    this.onSelectProject(this.graph.project_id);

    this.formData = this._fb.group({
      id: [this.graph.id, []],
      license_id: [this.graph.license_id, [Validators.maxLength(255)]],
      project_id: [this.graph.project_id, [Validators.maxLength(255)]],
      name: [this.graph.name, [Validators.required, Validators.minLength(1), Validators.maxLength(255)]],
      datapoints: [this.graph.datapoints, []],
      date_to: [date_to, []],
      date_from: [date_from, []],
      timerange_value: [this.graph.timerange_value?this.graph.timerange_value:'2', []],
      timerange_type: [this.graph.timerange_type?this.graph.timerange_type:'days', []],
      kind: [this.graph.kind?this.graph.kind:'line', []],
      width: [this.graph.width?this.graph.width:'100', []],
      width_type: [this.graph.width_type?this.graph.width_type:'%', []],
      height: [this.graph.height?this.graph.height:'480', []],
      height_type: [this.graph.height_type?this.graph.height_type:'px', []],
      comment: [this.graph.comment, []],
      roundvalues: [this.graph.roundvalues, []],
    });

    this.markDefaultRegisters();

    this.setDatePicker();

    this.componentState = "loaded";
  }

  onSelectLicense(license_id: any) {
    if(!license_id) {
      this.projectSelection = this.projects;
      return;
    }

    this.projectSelection = [];
    for(let i = 0; i < this.projects.length; i++) {
      if(this.projects[i].license_id == license_id) {
        this.projectSelection.push(this.projects[i]);
      }
    }

    if(this.formData) {
      this.formData.controls['project_id'].setValue('');
      this.onSelectProject('');
    }
  }

  onSelectProject(project_id: any) {
    if(!project_id) {
      this.registerSelection = this.deviceRegisters;
    } else {
      this.registerSelection = [];
      for (let i = 0; i < this.deviceRegisters.length; i++) {
        if (this.deviceRegisters[i].project_id == project_id) {
          this.registerSelection.push(this.deviceRegisters[i]);
        }
      }
    }

    for(let i = 0; i < this.deviceRegisters.length; i++) {
      this.deviceRegisters[i].marked = false;
    }
    this.markDefaultRegisters();

    this.currentRegister = null;
  }

  onChangeTimemode(newMode: any) {
    this.timeMode = newMode;
  }

  onKindChanged(evt: any) {
    let currentKind = this.formData.get('kind').value;
    if(this.aggregationMode != "sum" && (currentKind == "sankey" || currentKind == "pie" || currentKind == "pie_doughnut" || currentKind == "gauge")) {
      this.onChangeAggregation('sum');
    }
  }

  onChangeAggregation(newMode: any) {
    let currentKind = this.formData.get('kind').value;

    if(newMode != "sum" && (currentKind == "sankey" || currentKind == "pie" || currentKind == "pie_doughnut")) {
      this.globals.setData("toast_message", {title:'Graphen', content:'Diese Graphen-Art kann nur als aggregierte Summe dargestellt werden', type: 'info'});
      return;
    }
    if(newMode != "sum" && newMode != "latestvalue" && (currentKind == "gauge")) {
      this.globals.setData("toast_message", {title:'Graphen', content:'Diese Graphen-Art kann nur als aggregierte Summe oder letzter Wert dargestellt werden', type: 'info'});
      return;
    }

    this.aggregationMode = newMode;
  }

  onChangeAggregationKind(newMode: any) {
    this.aggregationKind = newMode;
  }

  onStartEditRegister(register: any) {
    this.registerToEdit = register;
    this.editregisterModal.show({nofooter: true});
  }

  onEditRegisterFinished(register: any) {
    this.registerToEdit = null;
    this.editregisterModal.hide();
  }

  onSubmit(data: any, isValid: boolean) {
    if(isValid) {
      this.submitted = true;

      let requestData: any = {formData: data, device_registers: []};

      data.timemode = this.timeMode;
      data.aggregationmode = this.aggregationMode;
      data.aggregationkind = this.aggregationKind;

      if(data.timemode != 'daterange') {
        data.date_to = null;
        data.date_from = null;
      }
      if(data.timemode != 'timerange') {
        data.timerange_value = null;
        data.timerange_type = null;
      }

      for(let i = 0; i < this.deviceRegisters.length; i++) {
        if(this.deviceRegisters[i].marked) {
          requestData.device_registers.push({
            id: this.deviceRegisters[i].id,
            color: this.deviceRegisters[i].color,
            measurement_unit_id: this.deviceRegisters[i].measurement_unit_id
          });
        }
      }

      this.network.sendRequest('/measurements/graphs/'+this.graph_id, 'PUT', requestData).then((data:any) => {
        this.globals.setData("toast_message", {title:'Graph', content:'Erfolgreich bearbeitet', type:'success'});
        this.finished.emit(true);

        this.submitted = false;
      }).catch((e: any) => {
        this.submitted = false;
        console.log(e);
        this.finished.emit(false);
      });
    }
  }

  setDatePicker() {
    setTimeout(function() {
      $('#date-from').datetimepicker({
        locale: 'de',
        format: 'DD.MM.YYYY'
      });
      $('#date-to').datetimepicker({
        locale: 'de',
        format: 'DD.MM.YYYY'
      });

        $('#date-from').on('dp.change', function(e) {
          this.formData.controls['date_from'].setValue($('#date_from').val());
        }.bind(this));

        $('#date-to').on('dp.change', function(e) {
          this.formData.controls['date_to'].setValue($('#date_to').val());
        }.bind(this));
    }.bind(this), 0);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
