import { Component, ElementRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import { GlobalsProvider } from './shared/providers/globals.provider';
import { NetworkProvider } from './shared/providers/network.provider';

declare const require: any;
var moment = require('moment');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  componentReady:Boolean = false;
  private subscriptions: Subscription = new Subscription();
  user_id: any = null;
  user: any = null;
  authorization: string = null;
  public loadingRouteConfig: boolean = false;

  constructor(private globals:GlobalsProvider, private network:NetworkProvider, private router:Router, private activatedRoute: ActivatedRoute, private elementRef:ElementRef) {
    moment.locale('de');

    this.authorization = window.localStorage.getItem('authorization');

    this.subscriptions.add(this.globals.addObservable('user').subscribe(
      data => {
        this.user = data;
      }
    ));

    this.subscriptions.add(this.globals.addObservable('user_id').subscribe(
      data => {
        this.user_id = data;
      }
    ));

    this.subscriptions.add(this.globals.addObservable('authorization').subscribe(
      data => {
        this.authorization = data;
        if(this.authorization == null || this.authorization=='') {
          this.user = null;
          this.user_id = null;
        }
      }
    ));

    this.router.events.subscribe(event => {
        if (event instanceof RouteConfigLoadStart) {
          this.loadingRouteConfig = true;
        } else if (event instanceof RouteConfigLoadEnd) {
          this.loadingRouteConfig = false;
        }
    });

    /*
    this.router.events
      .filter(event => event instanceof NavigationEnd)
      .map(() => this.activatedRoute)
      .map(route => {
        while (route.firstChild) route = route.firstChild;
        return route;
      })
      .filter(route => route.outlet === 'primary')
      .mergeMap(route => route.data)
      .subscribe((event) => {
        this.globals.setData('title_update', event['title']);
      });
  */
  }

  ngOnInit(){
    //setTimeout(this.checkLogin.bind(this), 100);
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
