import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {Observer} from 'rxjs';
import {Router}  from '@angular/router';

interface DataObj {
  [key: string]: any;
}

@Injectable()
export class GlobalsProvider {

  public data: DataObj = {};
  public observables:Object = {};
  observers:Object = {};

  public aggregationLabels: any = {
    'hourly': 'Stunde',
    'daily': 'Tag',
    'weekly': 'Woche',
    'monthly': 'Monat',
    'quarterly': 'Quartal',
    'yearly': 'Jahr',
    'sum': 'Summe',
  };

  public graphKinds: any = [
    {name: 'line', description: 'Linien'},
    {name: 'bar', description: 'Balken'},
    {name: 'bar_stacked', description: 'Balken (stacked)'},
    {name: 'pie', description: 'Kuchen'},
    {name: 'pie_doughnut', description: 'Donut'},
    {name: 'gauge', description: 'Tacho'},
    {name: 'sankey', description: 'Sankey'}
  ];

  constructor(public router:Router) {
  }

  public addObservable(key: any) {
    if(this.observers[key] == null) {
        this.observers[key] = [];
        this.observables[key] = [];
    }

    var observable = new Observable((observer:Observer<any>) => {
      this.observers[key].push(observer);
    });

    this.observables[key].push(observable);

    return observable;
  }

  public setData(key: any, value: any) {
    this.data[key] = value;

    if(this.observers[key] != null) {
      for (var i in this.observers[key]) {
        this.observers[key][i].next(this.data[key]);
      }
    }
  }

  public getData(key: any) {
    return this.data[key];
  }

  public getUser() {
    let user = this.getData("user");
    if(!user) {
      let storedUser = window.localStorage.getItem("user");
      if(storedUser) {
        user = JSON.parse(storedUser);
      }
    }

    return user;
  }

  public checkUserScope(scopes: any) {
    let user = this.getUser();

    if(!user || !user.usertype) {
        return false;
    }

    if(user.usertype == "admin") {
        return true;
    }

    if(!Array.isArray(scopes)) {
        scopes = [scopes];
    }

    if(!scopes || scopes.length == 0) {
      return true;
    }

    for(let i = 0; i < scopes.length; i++) {
        if(user.usertype == scopes[i]) {
            return true;
        } else if(user.scope) {
            for(let j = 0; j < user.scope.length; j++) {
                if(user.scope[j] == scopes[i]) {
                    return true;
                }
            }
        }
    }

    return false;
  }

  public hasAnyScopeOf(name: any) {
    let user = this.getUser();

    name = name + '_';

    if(!user || !user.usertype) {
        return false;
    }

    if(user.usertype == "admin") {
        return true;
    }

    if(!user.scope || user.scope.length == 0) {
      return false;
    }

    for(let j = 0; j < user.scope.length; j++) {
        if(user.scope[j].includes(name)) {
            return true;
        }
    }

    return false;
  }

  public saveUser(user: any) {
    //console.log("globals.saveUser: " + JSON.stringify(user));

    window.localStorage.setItem('user_id', user.id);
    this.setData('user_id', user.id);
    window.localStorage.setItem('user', JSON.stringify(user));
    this.setData('user', user);
  }

  public logout(reroute: boolean = true) {
    console.log("logout " + reroute);
    window.localStorage.setItem('user', '');
    this.setData('user', '');
    window.localStorage.setItem('authorization', '');
    this.setData('authorization', '');

    if(reroute) {
      this.router.navigate(['/auth/login']);
    }
  }

  public generateToken() {
    let d = new Date().getTime();
    let uuid = 'xxxxxxxy'.replace(/[xy]/g, function(c) {
      let r = (d + Math.random()*16)%16 | 0;
      d = Math.floor(d/16);
      return (c=='x' ? r : (r&0x3|0x8)).toString(16);
    });
    return uuid;
  }

  public getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min) ) + min;
  }

  public degreesToRadians(degrees){
    return degrees * (Math.PI/180);
  }

  public radiansToDegrees(radians) {
    return radians * (188/Math.PI);
  }

  inArray(needle, haystack) {
    for(let i=haystack.length; i--;){
      if(haystack[i]==needle){
        return true;
      }
    }

    return false;
  }

  formatSecondsDuration(duration: any) {
    duration = parseInt(duration);

    let days = duration / (24 * 3600);
    duration = duration % (24 * 3600);

    let hours = duration / 3600;
    duration = duration % (3600);

    let minutes = duration / 60;
    duration = duration % (60);

    let seconds = duration;

    let returnString: any = "";

    if(days > 0) {
      returnString += days.toFixed(0);
      if(Math.round(days)==1) {
        returnString += " Tag ";
      } else {
        returnString += " Tage ";
      }
    }
    if(hours > 0) {
      returnString += hours.toFixed(0);
      if(Math.round(hours)==1) {
        returnString += " Stunde ";
      } else {
        returnString += " Stunden ";
      }
    }
    if(minutes > 0) {
      returnString += minutes.toFixed(0);
      if(Math.round(minutes)==1) {
        returnString += " Minute ";
      } else {
        returnString += " Minuten ";
      }
    }

    returnString += seconds.toFixed(0);
    if(Math.round(seconds)==1) {
      returnString += " Sekunde ";
    } else {
      returnString += " Sekunden ";
    }

    return returnString;
  }
}
