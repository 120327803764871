import {Component, Input, EventEmitter, Output, ViewChild} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import { Subscription } from 'rxjs';

import { GlobalsProvider } from '../../providers/globals.provider';
import { NetworkProvider } from '../../providers/network.provider';

import { loadOpacity } from '../../animations/index';
import {ModalComponent} from '../modal/modal.component';

declare const $: any;
declare const require: any;
var moment = require('moment');

@Component({
  selector: 'measurements-graphedit-inline',
  templateUrl: './measurements-graphedit-inline.component.html',
  styleUrls: ['./measurements-graphedit-inline.component.css'],
  animations: [loadOpacity()]
})
export class MeasurementsGrapheditInlineComponent {
  @Output() update: EventEmitter<any> = new EventEmitter<any>();
  @Output() ready: EventEmitter<any> = new EventEmitter<any>();
  @Input('graph_id') graph_id: any;

  componentState: String = "init";
  public myUser: any;
  private subscriptions: Subscription = new Subscription();
  paramsSub: any;
  public formData: FormGroup;
  public submitted: boolean;
  public graph: any;
  public measurement_units: any;
  public timeMode: any = 'all';
  public aggregationMode: any = 'none';
  public aggregationKind: any = 'sum';
  public graphKinds: any;

  constructor(private router: Router, private route: ActivatedRoute, private globals:GlobalsProvider, public network:NetworkProvider, public _fb: FormBuilder) {
    this.myUser = this.globals.getData("user");

    this.subscriptions.add(this.globals.addObservable('user').subscribe(
      data => {
        this.myUser = data;
      }
    ));

    this.graphKinds = this.globals.graphKinds;
  }

  ngOnInit() {
    this.loadOptions();
  }

  loadOptions() {
    this.network.sendRequest('/measurements/graphs/options', 'GET').then((data:any) => {
      this.measurement_units = data.measurement_units;
      this.loadData();
    }).catch((e: any) => {
      console.log(e);
      this.update.emit(false);
    });
  }


  loadData() {
    this.network.sendRequest('/measurements/graphs/list', 'POST', {where: {id: this.graph_id}}).then((data:any) => {
      this.graph = data.graphs[0];
      this.setupForm();
    }).catch((e: any) => {
      console.log(e);
      this.update.emit(false);
    });
  }

  setupForm() {
    this.timeMode = this.graph.timemode ? this.graph.timemode : 'all';
    this.aggregationMode = this.graph.aggregationmode ? this.graph.aggregationmode : 'none';
    this.aggregationKind = this.graph.aggregationkind ? this.graph.aggregationkind : 'sum';
    let date_to = moment(this.graph.date_to).format('DD.MM.YYYY');
    let date_from = moment(this.graph.date_from).format('DD.MM.YYYY');

    this.formData = this._fb.group({
      id: [this.graph.id, []],
      datapoints: [this.graph.datapoints, []],
      date_to: [date_to, []],
      date_from: [date_from, []],
      timerange_value: [this.graph.timerange_value?this.graph.timerange_value:'2', []],
      timerange_type: [this.graph.timerange_type?this.graph.timerange_type:'days', []],
      kind: [this.graph.kind?this.graph.kind:'line', []],
      width: [this.graph.width?this.graph.width:'100', []],
      width_type: [this.graph.width_type?this.graph.width_type:'%', []],
      height: [this.graph.height?this.graph.height:'480', []],
      height_type: [this.graph.height_type?this.graph.height_type:'px', []],
      roundvalues: [this.graph.roundvalues, []],
    });

    this.setDatePicker();

    this.componentState = "loaded";

    this.ready.emit({type: "ready"});
  }

  onChangeTimemode(newMode: any) {
    this.timeMode = newMode;
  }

  onKindChanged(evt: any) {
    let currentKind = this.formData.get('kind').value;
    if(this.aggregationMode != "sum" && (currentKind == "sankey" || currentKind == "pie" || currentKind == "pie_doughnut" || currentKind == "gauge")) {
      this.onChangeAggregation('sum');
    }
  }

  onChangeAggregation(newMode: any) {
    let currentKind = this.formData.get('kind').value;

    if(newMode != "sum" && (currentKind == "sankey" || currentKind == "pie" || currentKind == "pie_doughnut")) {
      this.globals.setData("toast_message", {title:'Graphen', content:'Diese Graphen-Art kann nur als aggregierte Summe dargestellt werden', type: 'info'});
      return;
    }
    if(newMode != "sum" && newMode != "latestvalue" && (currentKind == "gauge")) {
      this.globals.setData("toast_message", {title:'Graphen', content:'Diese Graphen-Art kann nur als aggregierte Summe oder letzter Wert dargestellt werden', type: 'info'});
      return;
    }

    this.aggregationMode = newMode;
  }

  onChangeAggregationKind(newMode: any) {
    this.aggregationKind = newMode;
  }

  onSubmit(data: any, isValid: boolean) {
    if(isValid) {
      this.submitted = true;

      let requestData: any = {formData: data};

      data.license_id = this.graph.license_id;
      data.project_id = this.graph.project_id;
      data.timemode = this.timeMode;
      data.aggregationmode = this.aggregationMode;
      data.aggregationkind = this.aggregationKind;

      if(data.timemode != 'daterange') {
        data.date_to = null;
        data.date_from = null;
      }
      if(data.timemode != 'timerange') {
        data.timerange_value = null;
        data.timerange_type = null;
      }

      this.network.sendRequest('/measurements/graphs/'+this.graph_id, 'PUT', requestData).then((data:any) => {
        this.globals.setData("toast_message", {title:'Graph', content:'Erfolgreich bearbeitet', type:'success'});
        this.update.emit(true);

        this.submitted = false;
      }).catch((e: any) => {
        this.submitted = false;
        console.log(e);
        this.update.emit(false);
      });
    }
  }

  setDatePicker() {
    setTimeout(function() {
      $('#date-from').datetimepicker({
        locale: 'de',
        format: 'DD.MM.YYYY'
      });
      $('#date-to').datetimepicker({
        locale: 'de',
        format: 'DD.MM.YYYY'
      });

      $('#date-from').on('dp.change', function(e) {
        this.formData.controls['date_from'].setValue($('#date_from').val());
      }.bind(this));

      $('#date-to').on('dp.change', function(e) {
        this.formData.controls['date_to'].setValue($('#date_to').val());
      }.bind(this));
    }.bind(this), 0);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
