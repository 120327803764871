import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { GlobalsProvider } from '../../providers/globals.provider';

@Component({
  templateUrl: './status-message.component.html',
  selector: 'status-message'
})
export class StatusMessageComponent {
  private subscriptions: Subscription = new Subscription();
  status_message = null;

  constructor(private globals:GlobalsProvider) {
      this.subscriptions.add(this.globals.addObservable('status_message').subscribe(
          data => {
            if(data != null) {
                this.status_message = data;
                this.globals.setData('status_message', null);
            }
          }
      ));
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onDismissMessage($event) {
    this.status_message = null;
  }

}
