import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';

import { GlobalsProvider } from '../../providers/globals.provider';
import { NetworkProvider } from '../../providers/network.provider';

import { loadOpacity } from '../../animations';

@Component({
  selector: 'app-measurements-graph',
  templateUrl: './measurements-graph.component.html',
  styleUrls: ['./measurements-graph.component.css'],
  animations: [loadOpacity()]
})
export class MeasurementsGraphComponent {
  @Output() update: EventEmitter<any> = new EventEmitter<any>();
  @Input('graph_id') graph_id: any;
  @Input('container_id') container_id: any;

  public _additionalRegisters: any;
  @Input('additionalRegisters') set additionalRegisters(value: any) {
    this._additionalRegisters = value;
  }

  componentState: String = "init";
  paramsSub: any;
  public graph: any;
  public registers: any;
  public canEdit: boolean;
  public error: any = null;
  public showOverlay: boolean = false;
  public kindToLoad: any;

  constructor(private router: Router, private route: ActivatedRoute, private globals:GlobalsProvider, public network:NetworkProvider) {}

  ngAfterViewInit() {
    this.canEdit = this.globals.checkUserScope("measurementgraphs_edit");
    setTimeout(this.loadData.bind(this), 1);
  }

  loadData() {
    this.graph = null;
    this.registers = null;
    this.error = null;
    this.componentState = "loading";

    this.network.sendRequest('/measurements/graphs/list', 'POST', {where: {id: this.graph_id}}).then((data:any) => {
      if(!data.graphs || data.graphs.length <= 0) {
        return;
      }

      this.graph = data.graphs[0];
      this.registers = this.graph.DeviceRegisters;

      if(!this.registers || this.registers.length == 0) {
        //this.error = 'Graph hat keine zugewiesenen Register';
        //return;
      }

      this.prepareGraphSize();
      this.setKindToLoad();

      this.componentState = "loaded";
      setTimeout(() => this.update.emit({type: "ready"}), 1);
    }).catch((e: any) => {
      this.error = "Fehler Initialisierung des Graphs";
      console.log(e);
    });
  }

  prepareGraphSize() {
    if(!this.graph.width || isNaN(this.graph.width) ||  !this.graph.width_type) {
      this.graph.width = "100%";
    } else {
      this.graph.width = this.graph.width + this.graph.width_type;
    }

    if(!this.graph.height || isNaN(this.graph.height) || !this.graph.height_type) {
      this.graph.height = "480px";
    } else {
      this.graph.height = this.graph.height + this.graph.height_type;
    }
  }

  hasRegisters() {
    return ((this._additionalRegisters&&this._additionalRegisters.length > 0) || (this.registers && this.registers.length > 0));
  }

  onInlineEdit(evt: any) {
    this.loadData();
  }

  setKindToLoad() {
    switch(this.graph.kind) {
      case "line":
      case "bar":
      case "bar_stacked":
        this.kindToLoad = 'default';
        break;
      case "pie":
      case "pie_doughnut":
        this.kindToLoad = 'pie';
        break;
      case "gauge":
        this.kindToLoad = 'gauge';
        break;
      case "sankey":
        this.kindToLoad = 'sankey';
        break;
      default:
        this.kindToLoad = 'default';
        break;
    }
  }

  onStartEdit() {
    this.globals.setData('edit-graph', {
      graph_id: this.graph_id,
      additionalRegisters: this._additionalRegisters,
      cb: this.onEditFinished.bind(this)
    });
  }

  onEditFinished(success: boolean) {
    if(success) {
      setTimeout(this.loadData.bind(this), 1);
    }
  }

  onGraphUpdate(evt: any) {
    this.update.emit(evt);
  }

  onMouseEntered() {
    this.showOverlay = true;
  }

  onMouseLeft() {
    this.showOverlay = false;
  }

  ngOnDestroy() {
  }

}
