import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-graphregister-edit',
  templateUrl: './graphregister-edit.component.html',
  styleUrls: ['./graphregister-edit.component.css']
})
export class GraphregisterEditComponent implements OnInit {
  @Input('register') register: any;
  @Input('measurementUnits') measurementUnits: any;
  @Output() finished: EventEmitter<any> = new EventEmitter<any>();

  public submitted: any = false;

  constructor() { }

  ngOnInit() {
  }

  onCancel() {
    this.finished.emit(null);
  }

  onSubmit() {
    this.finished.emit(this.register);
  }
}
