import { Component, OnInit } from '@angular/core';
import { GlobalsProvider } from '../../providers/globals.provider';

@Component({
  selector: 'app-messagesmenu',
  templateUrl: './messagesmenu.component.html',
  styleUrls: ['./messagesmenu.component.css']
})
export class MessagesmenuComponent implements OnInit {

  public myUser: any;

  constructor(private globals:GlobalsProvider) {
    this.myUser = this.globals.getData("user");
  }

  ngOnInit() {
  }

}
