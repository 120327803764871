import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GlobalsProvider} from '../../../providers/globals.provider';
import {NetworkProvider} from '../../../providers/network.provider';
import {AppSettings} from '../../../../app.settings';

declare let $:any;
declare let require:any;
declare let echarts;
var moment = require('moment');

@Component({
  selector: 'app-measurements-graph-sankey',
  templateUrl: './measurements-graph-sankey.component.html',
  styleUrls: ['./measurements-graph-sankey.component.css']
})
export class MeasurementsGraphSankeyComponent implements OnInit {
  public static SUM_REGISTERNAME: any = "Gesamt";

  @ViewChild('chartDom', {static: false}) chartDom: ElementRef;
  @Output() update: EventEmitter<any> = new EventEmitter<any>();
  @Input('graph') graph: any;
  @Input('registers') registers: any;
  @Input('graph_id') graph_id: any;
  @Input('container_id') container_id: any;

  private _additionalRegisters: any = [];
  @Input('additionalRegisters') set additionalRegisters(value: any) {
    if(!value) {
      this._additionalRegisters = [];
    } else {
      this._additionalRegisters = value;
      this.reloadRegisters();
    }
  }

  componentState: String = "init";
  public chart: any;
  public error: any = null;
  public chartOptions: any;
  resizeCheckInterval: any;
  public containerWidth: any = 0;
  public showOverlay: boolean = false;
  public measurements: any = [];
  public registersToLoad: any = [];

  fetchingData: boolean = false;
  fetchLimitInterval: any = 0;
  fetchRegisterIds: any;
  fetchDateFrom: any;
  fetchDateTo: any;

  constructor(private router: Router, private route: ActivatedRoute, private globals:GlobalsProvider, public network:NetworkProvider) {

  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout(this.loadData.bind(this), 1);
  }

  reloadRegisters() {
    if(this.fetchingData) {
      return;
    }
    this.resetChart();
    if(this.chart) {
      this.chart.dispose();
      this.chart = null;
    }
    this.loadData();
  }

  loadData() {
    if(this.fetchingData) {
      return;
    }

    this.measurements = [];

    this.registersToLoad = [...this.registers, ...this._additionalRegisters];

    for(let i = 0; i < this.registersToLoad.length; i++) {
      //console.log("registerToLoad: " + this.registersToLoad[i].id);
      let measurementUnit;
      if(this.registersToLoad[i].GraphDeviceRegister && this.registersToLoad[i].GraphDeviceRegister.MeasurementUnit) {
        measurementUnit = this.registersToLoad[i].GraphDeviceRegister.MeasurementUnit;
      } else {
        measurementUnit = this.registersToLoad[i].MeasurementUnit;
      }

      let color = null;
      if(this.registersToLoad[i].color) {
        color = this.registersToLoad[i].color;
      } else if(this.registersToLoad[i].GraphDeviceRegister && this.registersToLoad[i].GraphDeviceRegister.color && this.registersToLoad[i].GraphDeviceRegister.color != "#ffffff") {
        color = this.registersToLoad[i].GraphDeviceRegister.color;
      } else if(measurementUnit && measurementUnit.color_graph && measurementUnit.color_graph != "#ffffff") {
        color = measurementUnit.color_graph;
      }

      this.measurements.push({
        source: MeasurementsGraphSankeyComponent.SUM_REGISTERNAME,
        target: this.getRegisterLegendName(this.registersToLoad[i]),
        value: 0,
        unit: measurementUnit,
        itemStyle: {
          color: color,
          borderColor: color
        }
      });
    }

    this.measurements.push({
      value: 0,
      name: MeasurementsGraphSankeyComponent.SUM_REGISTERNAME,
      itemStyle: {
        color: '#3b3b3b',
        borderColor: '#3b3b3b'
      }
    });

    this.componentState = "loaded";

    setTimeout(this.setupChart.bind(this), 0);
  }

  getRegisterLegendNameById(register_id: any) {
    for(let i = 0; i < this.registersToLoad.length; i++) {
      if(this.registersToLoad[i].id == register_id) {
        return this.getRegisterLegendName(this.registersToLoad[i]);
      }
    }

    return null;
  }

  getRegisterLegendName(register: any) {
    let name = "";
    if(register.Device) {
      name = register.Device.name_graph ? register.Device.name_graph : register.Device.name;
      name += "; ";
    }
    name += (register.name_graph) ? register.name_graph : register.name;
    return name;
  }

  async getMeasurements() {
    this.fetchingData = true;
    this.fetchLimitInterval = AppSettings.DATAPOINTS_INTERVAL;
    this.fetchRegisterIds = [];
    this.fetchDateFrom = this.graph.date_from ? this.graph.date_from : null;
    this.fetchDateTo = this.graph.date_to ? this.graph.date_to : null;

    for (let i = 0; i < this.registersToLoad.length; i++) {
      this.fetchRegisterIds.push(this.registersToLoad[i].id);
    }

    let filter = {
      license_id: this.graph.license_id,
      project_id: this.graph.project_id,
      device_register_ids: this.fetchRegisterIds,
      order: [["timestamp_begin", "ASC"]],
      limit: this.fetchLimitInterval,
      timemode: this.graph.timemode,
      aggregationmode: 'sum',
      aggregationkind: this.graph.aggregationkind,
      timerange_value: this.graph.timerange_value,
      timerange_type: this.graph.timerange_type,
      date_from: this.fetchDateFrom,
      date_to: this.fetchDateTo,
    };

    let latestResponse: any = {};

    while(this.fetchingData && latestResponse != null && this.chart != null) {
      //console.log("Fetching graph data...");
      try {
        latestResponse = await this.network.sendRequest('/measurements/measurements/get', 'POST', filter, {preferWS: false});
        if(!latestResponse || !latestResponse.measurements) {
          //console.log("Fetch graph data. No More Data.");
          latestResponse = null;
        }
      } catch (e) {
        //console.log("Fetch graph data ERR");
        latestResponse = null;
      }

      if(latestResponse == null) {
        //console.log("Fetch graph data. latestResponse was NULL");
        break;
      }

      let newMeasurements = latestResponse.measurements;

      let nodata = true;
      for (let register_id in newMeasurements) {
        if(nodata && newMeasurements[register_id].length > 0 && filter.limit <= newMeasurements[register_id].length) {
          nodata = false;
        }
        for (let i = 0; i < newMeasurements[register_id].length; i++) {
          this.addMeasurement(register_id, newMeasurements[register_id][i], false);
          filter.date_from = newMeasurements[register_id][i].timestamp_begin;
        }
      }

      if(nodata ) {
        this.fetchingData = false;
        latestResponse = null;
      }
    }

    if(this.graph != null) {
      this.setCurrentGraphData();
      this.subscribeGraph();
    }

    this.fetchingData = false;
  }

  addMeasurement(register_id, measurement, setGraphData = false) {
    //console.log("addMeasurement " + JSON.stringify(measurement));

    if(this.graph.roundvalues != null && !isNaN(this.graph.roundvalues)) {
      this.graph.roundvalues = Math.max(0, this.graph.roundvalues);
      measurement.value = parseFloat(measurement.value).toFixed(this.graph.roundvalues);
    }

    let registerLegendName = this.getRegisterLegendNameById(register_id);

    for(let j = 0; j < this.measurements.length; j++) {
      if(this.measurements[j].target == registerLegendName) {
        this.measurements[j].value = measurement.value;
      }
    }

    if(setGraphData) {
      this.setCurrentGraphData();
    }
  }

  calcMeasurementSum() {
    let sumMeasurement;

    for(let i = 0; i < this.measurements.length; i++) {
      if(this.measurements[i].name == MeasurementsGraphSankeyComponent.SUM_REGISTERNAME) {
        sumMeasurement = this.measurements[i];
        sumMeasurement.value = 0;
      }
    }

    if(!sumMeasurement) {
      return;
    }

    for(let i = 0; i < this.measurements.length; i++) {
      if(this.measurements[i].name != MeasurementsGraphSankeyComponent.SUM_REGISTERNAME) {
        sumMeasurement.value += this.measurements[i].value;
      }
    }
  }

  getSeriesData() {
    let data = [];

    //console.log("getSeriesData this.measurements: " + JSON.stringify(this.measurements));

    for(let i = 0; i < this.measurements.length; i++) {
      data.push({
        name: this.measurements[i].target ? this.measurements[i].target : this.measurements[i].name,
        itemStyle: this.measurements[i].itemStyle
      });
    }

    //console.log("getSeriesData " + JSON.stringify(data));

    return data;
  }

  getSeriesLinks() {
    let links = [];

    for(let i = 0; i < this.measurements.length; i++) {
      if(this.measurements[i].source && this.measurements[i].target) {
        links.push(this.measurements[i]);
      }
    }

    //console.log("getSeriesLinks " + JSON.stringify(links));

    return links;
  }

  setCurrentGraphData() {
    if(!this.chart) {
      return;
    }

    this.calcMeasurementSum();

    this.chartOptions.series.data = this.getSeriesData();
    this.chartOptions.series.links = this.getSeriesLinks();
    this.chart.setOption(this.chartOptions, {notMerge: true, lazyUpdate: false, silent: false});
  }

  subscribeGraph() {
    this.network.subscribe('/graph/measurements/'+this.graph_id, this.dataReceived, function(err) {});
  }

  unsubscribeGraph() {
    this.network.unsubscribe('/graph/measurements/'+this.graph_id);
  }

  dataReceived = (data, flags) => {
    //console.log("graph measurement received " + JSON.stringify(data));

    if(this.componentState != "loaded" || !this.graph) {
      return;
    }

    this.addMeasurement(data.device_register_id, data, true);
  };

  getMeasurementValue(name: any, includeUnit: any = false) {
    for(let i = 0; i < this.measurements.length; i++) {
      if(this.measurements[i].target == name || this.measurements[i].name == name) {
        let valueToReturn = this.measurements[i].value;
        if(includeUnit && this.measurements[i].unit) {
          valueToReturn += " " + this.measurements[i].unit.name;
        }
        return valueToReturn;
      }
    }

    return 0;
  }

  getMeasurementPercentage(name: any) {
    let sumValue = 0;
    let registerValue = 0;

    for(let i = 0; i < this.measurements.length; i++) {
      if(this.measurements[i].name == MeasurementsGraphSankeyComponent.SUM_REGISTERNAME) {
        sumValue = this.measurements[i].value;
      }
    }

    for(let i = 0; i < this.measurements.length; i++) {
      if(this.measurements[i].target == name || this.measurements[i].name == name) {
        registerValue = this.measurements[i].value;
      }
    }

    if(sumValue > 0) {
      return (registerValue / sumValue * 100).toFixed(2);
    } else {
      return 0;
    }
  }

  getCustomLabel(name: any) {
    return this.getMeasurementPercentage(name) + '%: ' + name;
  }

  getCustomTooltip(name: any) {
    let valToReturn = name;
    valToReturn += '<br/>Wert: ' + this.getMeasurementValue(name, true);
    valToReturn += '<br/>Anteil: ' + this.getMeasurementPercentage(name) + ' %';
    return valToReturn;
  }

  async setupChart() {
    //console.log("this.chartDom " + this.chartDom + " " + this.chartDom.nativeElement);
    this.chart = echarts.init(
      this.chartDom.nativeElement
    );

    this.chartOptions = {
      tooltip: {
        trigger: 'item',
        formatter: (d) => {
          //console.log(JSON.stringify(d));
          let name = d.data.target ? d.data.target : d.data.name;
          return this.getCustomTooltip(name);
        },
      },
      legend: {
        orient: 'horizontal',
        left: 'left',
        top: 'bottom',
      },
      label: {
        color: 'rgba(0,0,0,0.9)',
        formatter: (d) => {
          //console.log(JSON.stringify(d));
          return this.getCustomLabel(d.name);
        }
      },
      series: {
        lineStyle: {
          color: 'target',
          curveness: 0.5
        },
        name: this.graph.name,
        type: 'sankey',
        data: [],
        links: [],
        //layout: 'none',
        emphasis: {
          focus: 'adjacency'
        },
      }
    };

    if (this.chartOptions && typeof this.chartOptions === "object") {
      this.chart.setOption(this.chartOptions, {notMerge: true, lazyUpdate: false, silent: false});
    }

    await this.getMeasurements();

    this.resizeCheckInterval = setInterval(this.checkForResize.bind(this), 250);

    this.update.emit({type: "ready"});
  }

  checkForResize() {
    if(this.containerWidth != this.chartDom.nativeElement.clientWidth) {
      this.containerWidth = this.chartDom.nativeElement.clientWidth;
      if(this.chart) {
        setTimeout(() => {
          this.chart.resize();
        }, 1);
      }
    }
  }

  onMouseEntered() {
    this.showOverlay = true;
  }

  onMouseLeft() {
    this.showOverlay = false;
  }

  resetChart() {
    this.fetchingData = false;
    this.unsubscribeGraph();
    clearInterval(this.resizeCheckInterval);
  }

  ngOnDestroy() {
    this.resetChart();
    this.chart = null;
  }

}
