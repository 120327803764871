import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GlobalsProvider} from '../../../providers/globals.provider';
import {NetworkProvider} from '../../../providers/network.provider';
import {Subscription} from 'rxjs';
import {AppSettings} from '../../../../app.settings';

declare let $:any;
declare let require:any;
declare let echarts;
var moment = require('moment');

@Component({
  selector: 'app-measurements-graph-gauge',
  templateUrl: './measurements-graph-gauge.component.html',
  styleUrls: ['./measurements-graph-gauge.component.css']
})
export class MeasurementsGraphGaugeComponent {
  @ViewChild('chartDom', {static: false}) chartDom: ElementRef;
  @Output() update: EventEmitter<any> = new EventEmitter<any>();
  @Input('graph') graph: any;
  @Input('registers') registers: any;
  @Input('graph_id') graph_id: any;
  @Input('container_id') container_id: any;

  private _additionalRegisters: any = [];
  @Input('additionalRegisters') set additionalRegisters(value: any) {
    if(!value) {
      this._additionalRegisters = [];
    } else {
      this._additionalRegisters = value;
      this.reloadRegisters();
    }
  }

  componentState: String = "init";
  public chart: any;
  public error: any = null;
  public chartOptions: any;
  resizeCheckInterval: any;
  public containerWidth: any = 0;
  public showOverlay: boolean = false;
  public measurements: any = [];
  public colorPalette: any = [];
  public valueMin: any = 0;
  public valueMax: any = 100;
  public registersToLoad: any = [];

  fetchingData: boolean = false;
  fetchLimitInterval: any = 0;
  fetchRegisterIds: any;
  fetchDateFrom: any;
  fetchDateTo: any;

  constructor(private router: Router, private route: ActivatedRoute, private globals:GlobalsProvider, public network:NetworkProvider) {

  }

  ngAfterViewInit() {
    setTimeout(this.loadData.bind(this), 1);
  }

  getRegisterLegendName(register: any) {
    let name = "";
    if(register.Device) {
      name = register.Device.name_graph ? register.Device.name_graph : register.Device.name;
      name += "; ";
    }
    name += (register.name_graph) ? register.name_graph : register.name;
    return name;
  }

  reloadRegisters() {
    if(this.fetchingData) {
      return;
    }
    this.resetChart();
    if(this.chart) {
      this.chart.dispose();
      this.chart = null;
    }
    this.loadData();
  }

  loadData() {
    if(this.fetchingData) {
      return;
    }

    this.registersToLoad = [...this.registers, ...this._additionalRegisters];

    this.measurements = [];
    for(let i = 0; i < this.registersToLoad.length; i++) {
      let measurementUnit;
      if(this.registersToLoad[i].GraphDeviceRegister && this.registersToLoad[i].GraphDeviceRegister.MeasurementUnit) {
        measurementUnit = this.registersToLoad[i].GraphDeviceRegister.MeasurementUnit;
      } else {
        measurementUnit = this.registersToLoad[i].MeasurementUnit;
      }

      let color = null;
      if(this.registersToLoad[i].color) {
        color = this.registersToLoad[i].color;
        this.addColorToPalette(color);
      } else if(this.registersToLoad[i].GraphDeviceRegister && this.registersToLoad[i].GraphDeviceRegister.color && this.registersToLoad[i].GraphDeviceRegister.color != "#ffffff") {
        color = this.registersToLoad[i].GraphDeviceRegister.color;
        this.addColorToPalette(color);
      } else if(measurementUnit && measurementUnit.color_graph && measurementUnit.color_graph != "#ffffff") {
        color = measurementUnit.color_graph;
        this.addColorToPalette(color);
      }

      this.measurements.push({
        value: 0,
        name: this.getRegisterLegendName(this.registersToLoad[i])
      });
    }

    this.componentState = "loaded";

    setTimeout(this.setupChart.bind(this), 0);
  }

  addColorToPalette(color: any) {
    let found = false;
    for(let i = 0; i < this.colorPalette.length; i++) {
      if(color == this.colorPalette[i]) {
        found = true;
        break;
      }
    }

    if(!found) {
      this.colorPalette.push(color);
    }
  }

  getRegisterLegendNameById(register_id: any) {
    for(let i = 0; i < this.registersToLoad.length; i++) {
      if(this.registersToLoad[i].id == register_id) {
        return this.getRegisterLegendName(this.registersToLoad[i]);
      }
    }

    return null;
  }

  async getMeasurements() {
    this.fetchingData = true;
    this.fetchLimitInterval = AppSettings.DATAPOINTS_INTERVAL;
    this.fetchRegisterIds = [];
    this.fetchDateFrom = this.graph.date_from ? this.graph.date_from : null;
    this.fetchDateTo = this.graph.date_to ? this.graph.date_to : null;

    for (let i = 0; i < this.registersToLoad.length; i++) {
      this.fetchRegisterIds.push(this.registersToLoad[i].id);
    }

    let filter = {
      //license_id: this.graph.license_id,
      //project_id: this.graph.project_id,
      device_register_ids: this.fetchRegisterIds,
      order: [["timestamp_begin", "ASC"]],
      limit: this.fetchLimitInterval,
      timemode: this.graph.timemode,
      aggregationmode: this.graph.aggregationmode,
      aggregationkind: this.graph.aggregationkind,
      timerange_value: this.graph.timerange_value,
      timerange_type: this.graph.timerange_type,
      date_from: this.fetchDateFrom,
      date_to: this.fetchDateTo,
    };

    let latestResponse: any = {};

    while(this.fetchingData && latestResponse != null && this.chart != null) {
      //console.log("Fetching graph data...");
      try {
        latestResponse = await this.network.sendRequest('/measurements/measurements/get', 'POST', filter, {preferWS: false});
        if(!latestResponse || !latestResponse.measurements) {
          //console.log("Fetch graph data. No More Data.");
          latestResponse = null;
        }
      } catch (e) {
        //console.log("Fetch graph data ERR");
        latestResponse = null;
      }

      if(latestResponse == null) {
        //console.log("Fetch graph data. latestResponse was NULL");
        break;
      }

      let newMeasurements = latestResponse.measurements;

      let nodata = true;
      for (let register_id in newMeasurements) {
        if(nodata && newMeasurements[register_id].length > 0 && filter.limit <= newMeasurements[register_id].length) {
          nodata = false;
        }
        for (let i = 0; i < newMeasurements[register_id].length; i++) {
          this.addMeasurement(register_id, newMeasurements[register_id][i], false);
          filter.date_from = newMeasurements[register_id][i].timestamp_begin;
        }
      }

      if(nodata ) {
        this.fetchingData = false;
        latestResponse = null;
      }
    }

    if(this.graph != null) {
      this.setCurrentGraphData();
      this.subscribeGraph();
    }

    this.fetchingData = false;
  }

  addMeasurement(register_id, measurement, setGraphData = false) {
    //console.log("addMeasurement " + JSON.stringify(measurement));

    if(this.graph.roundvalues != null && !isNaN(this.graph.roundvalues)) {
      this.graph.roundvalues = Math.max(0, this.graph.roundvalues);
      measurement.value = parseFloat(measurement.value).toFixed(this.graph.roundvalues);
    }

    let registerLegendName = this.getRegisterLegendNameById(register_id);

    for(let j = 0; j < this.measurements.length; j++) {
      if(this.measurements[j].name == registerLegendName) {
        this.measurements[j].value = measurement.value;
        if(measurement.value < this.valueMin) {
          this.valueMin = measurement.value;
        }
        if(measurement.value > this.valueMax) {
          this.valueMax = measurement.value;
        }
      }
    }

    if(setGraphData) {
      this.setCurrentGraphData();
    }
  }

  setCurrentGraphData() {
    if(!this.chart) {
      return;
    }

    this.chartOptions.series = this.getSeriesObject();
    this.chart.setOption(this.chartOptions, {notMerge: true, lazyUpdate: false, silent: false});
  }

  subscribeGraph() {
    this.network.subscribe('/graph/measurements/'+this.graph_id, this.dataReceived, function(err) {});
  }

  unsubscribeGraph() {
    this.network.unsubscribe('/graph/measurements/'+this.graph_id);
  }

  dataReceived = (data, flags) => {
    //console.log("graph measurement received " + JSON.stringify(data));

    if(this.componentState != "loaded" || !this.graph) {
      return;
    }

    this.addMeasurement(data.device_register_id, data, true);
  };

  getSeriesObject() {
    return {
      name: this.graph.name,
      type: 'gauge',
      min: this.valueMin,
      max: this.valueMax,
      data: this.measurements,
      color: this.colorPalette,
      progress: {
        show: true
      },
      detail: {
        valueAnimation: true,
        formatter: '{value}'
      },
    };
  }

  async setupChart() {
    //console.log("this.chartDom " + this.chartDom + " " + this.chartDom.nativeElement);
    this.chart = echarts.init(this.chartDom.nativeElement);

    this.chartOptions = {
      tooltip: {
        trigger: 'item'
      },
      series: [
        this.getSeriesObject()
      ]
    };

    if (this.chartOptions && typeof this.chartOptions === "object") {
      this.chart.setOption(this.chartOptions, {notMerge: true, lazyUpdate: false, silent: false});
    }

    await this.getMeasurements();

    this.resizeCheckInterval = setInterval(this.checkForResize.bind(this), 250);

    this.update.emit({type: "ready"});
  }

  checkForResize() {
    if(this.containerWidth != this.chartDom.nativeElement.clientWidth) {
      this.containerWidth = this.chartDom.nativeElement.clientWidth;
      if(this.chart) {
        setTimeout(() => {
          this.chart.resize();
        }, 1);
      }
    }
  }

  onMouseEntered() {
    this.showOverlay = true;
  }

  onMouseLeft() {
    this.showOverlay = false;
  }

  resetChart() {
    this.fetchingData = false;
    this.unsubscribeGraph();
    clearInterval(this.resizeCheckInterval);
  }

  ngOnDestroy() {
    this.resetChart();
    this.chart = null;
  }
}
