import { environment } from '../environments/environment';

export class AppSettings {
   public static get APP_ENV(): string { return 'dev'; } // local | dev | prod
   public static get APP_VERSION(): string { return '2016.10.25-12.16.46'; }

   public static get DATAPOINTS_MAX(): any {return 3000; }; // Hard limit of fetching datapoints per graph.
   public static get DATAPOINTS_INTERVAL(): any {return 5000; }; //Datapoints limitation per request

   public static get PROTOCOL(): string {

    return 'http://';
   }

   public static get URL(): string {
     return "api.internetofmycompany.com";

    if(environment.production) {
        return "api.internetofmycompany.com";
    } else {
        return 'localhost:8100';
      //return "api.internetofmycompany.com";
    }
   }

   public static get WS_URL(): string {
     return "api.internetofmycompany.com";

    if(environment.production) {
        return "api.internetofmycompany.com";
    } else {
        return 'localhost:8100';
      //return "api.internetofmycompany.com";
    }
   }

}
