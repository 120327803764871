import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, SimpleChange } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import { Subscription } from 'rxjs';

import { GlobalsProvider } from '../../../providers/globals.provider';
import { NetworkProvider } from '../../../providers/network.provider';

import { loadOpacity } from '../../../animations';
import {AppSettings} from '../../../../app.settings';

declare let $:any;
declare let require:any;
declare let echarts;
var moment = require('moment');

@Component({
  selector: 'app-measurements-graph-default',
  templateUrl: './measurements-graph-default.component.html',
  styleUrls: ['./measurements-graph-default.component.css'],
  animations: [loadOpacity()]
})
export class MeasurementsGraphDefaultComponent {
  @ViewChild('chartDom', {static: false}) chartDom: ElementRef;
  @Output() update: EventEmitter<any> = new EventEmitter<any>();
  @Input('graph') graph: any;
  @Input('registers') registers: any;
  @Input('graph_id') graph_id: any;
  @Input('container_id') container_id: any;

  private _additionalRegisters: any = [];
  @Input('additionalRegisters') set additionalRegisters(value: any) {
    if(!value) {
      this._additionalRegisters = [];
    } else {
      this._additionalRegisters = value;
      this.reloadRegisters();
    }
  }

  componentState: String = "init";
  public error: any = null;
  public chart: any;
  public chartOptions: any;
  public measurements: any = [];
  public yAxisSettings: any;
  public xAxisSettings: any;
  public visualMap: any = null;
  public legend: any = {
    data: [],
    type: 'scroll',
    show: true
  };
  resizeCheckInterval: any;
  public containerWidth: any = 0;
  public showOverlay: boolean = false;
  public registersToLoad: any = [];

  fetchingData: boolean = false;
  fetchLimitInterval: any = 0;
  fetchRegisterIds: any;
  fetchDateFrom: any;
  fetchDateTo: any;

  constructor(private router: Router, private route: ActivatedRoute, private globals:GlobalsProvider, public network:NetworkProvider) {

  }

  ngAfterViewInit() {
    setTimeout(this.loadData.bind(this), 1);
  }

  getRegisterLegendName(register: any) {
    let name = "";
    if(register.Device) {
      name = register.Device.name_graph ? register.Device.name_graph : register.Device.name;
      name += "; ";
    }
    name += (register.name_graph) ? register.name_graph : register.name;
    return name;
  }

  reloadRegisters() {
    console.log("graph reloadRegisters");
    if(this.fetchingData) {
      return;
    }
    this.resetChart();
    if(this.chart) {
      this.chart.dispose();
      this.chart = null;
    }
    this.loadData();
  }

  loadData() {
    console.log("graph loadData");
    if(this.fetchingData) {
      return;
    }

    this.registersToLoad = [...this.registers, ...this._additionalRegisters];

    let yAxisName: any;
    this.measurements = [];
    for(let i = 0; i < this.registersToLoad.length; i++) {
      this.legend.data.push(this.getRegisterLegendName(this.registersToLoad[i]));

      let measurementUnit;
      if(this.registersToLoad[i].GraphDeviceRegister && this.registersToLoad[i].GraphDeviceRegister.MeasurementUnit) {
        measurementUnit = this.registersToLoad[i].GraphDeviceRegister.MeasurementUnit;
      } else {
        measurementUnit = this.registersToLoad[i].MeasurementUnit;
      }

      let color = null;
      if(this.registersToLoad[i].color) {
        color = this.registersToLoad[i].color;
      } else if(this.registersToLoad[i].GraphDeviceRegister && this.registersToLoad[i].GraphDeviceRegister.color && this.registersToLoad[i].GraphDeviceRegister.color != "#ffffff") {
        color = this.registersToLoad[i].GraphDeviceRegister.color;
      } else if(measurementUnit && measurementUnit.color_graph && measurementUnit.color_graph != "#ffffff") {
        color = measurementUnit.color_graph;
      }

      let newSeries = this.getSeriesDefaultSettings(this.registersToLoad[i], color);

      // Set Unit name of Register(s)
      if(measurementUnit) {
        if(!yAxisName) {
          yAxisName = measurementUnit.name;
        } else if(yAxisName != measurementUnit.name) {
          yAxisName = "Werte";
        }
      }

      // Check data threshold and set them up
      if(this.registersToLoad[i].DeviceRegisterThresholds && this.registersToLoad[i].DeviceRegisterThresholds.length > 0) {
        /*
        if(!this.visualMap) {
          this.visualMap = {
                type: 'piecewise',
                top: 30,
                right: 10,
                pieces: [],
                outOfRange: {
                    color: '#999'
                }
            };
        }
         */
        if(!newSeries['markLine']) {
          newSeries['markLine'] = {
            silent: false,
            data: []
          };
        }

        if(this.registersToLoad[i].DeviceRegisterThresholds) {
          for(let j = 0; j < this.registersToLoad[i].DeviceRegisterThresholds.length; j++) {
            newSeries['markLine'].data.push({yAxis: parseFloat(this.registersToLoad[i].DeviceRegisterThresholds[j].value)});
            /*
            this.visualMap.pieces.push({
                gt: 0,
                lte: parseFloat(this.registersToLoad[i].DeviceRegisterThresholds[j].value),
                color: '#00832e'
            });
            this.visualMap.pieces.push({
                gt: parseFloat(this.registersToLoad[i].DeviceRegisterThresholds[j].value),
                color: '#e90016'
            });
             */
          }
        }
      }

      this.measurements.push(newSeries);
    }

    this.setYAxisSettings(yAxisName);
    this.setXAxisSettings();

    this.componentState = "loaded";

    setTimeout(this.setupChart.bind(this), 0);
  }

  getSeriesDefaultSettings(register: any, color: any) {
    let newSeries = {
      register_id: register.id,
      name: this.getRegisterLegendName(register),
      //barCategoryGap: '0%',
      //showBackground: true,
      //backgroundStyle: {color: 'rgba(180, 180, 180, 0.2)'},
      smooth: true,
      connectNulls: false,
      data: [],
      markLine: null,
      color: color
    };

    switch(this.graph.kind) {
      case "bar":
        newSeries['type'] = 'bar';
        newSeries['barGap'] = '0%';
        break;
      case "bar_stacked":
        newSeries['type'] = 'bar';
        newSeries['stack'] = 'Stack1';
        break;
      default:
        newSeries['type'] = 'line';
        break;
    }

    return newSeries;
  }

  setYAxisSettings(yAxisName: any) {
    this.yAxisSettings = {
      name: yAxisName,
      type: 'value',
      boundaryGap: ['5%', '5%'],
      splitLine: {
        show: false
      }
    };
  }

  setXAxisSettings() {
    if(!this.graph.aggregationmode || this.graph.aggregationmode == "none") {
      this.xAxisSettings = {
        name: 'Zeit',
        type: 'time',
        splitLine: {
          show: false
        }
      };
    } else {
      this.xAxisSettings = {
        name: this.globals.aggregationLabels[this.graph.aggregationmode],
        type: 'category',
        splitLine: {
          show: false
        },
        axisLabel: {
          formatter: (function(value){
            return value;
          })
        },
      };
    }
  }

  async getMeasurements() {
    this.fetchingData = true;
    this.fetchLimitInterval = AppSettings.DATAPOINTS_INTERVAL;
    this.fetchRegisterIds = [];
    this.fetchDateFrom = this.graph.date_from ? this.graph.date_from : null;
    this.fetchDateTo = this.graph.date_to ? this.graph.date_to : null;

    for (let i = 0; i < this.registersToLoad.length; i++) {
      this.fetchRegisterIds.push(this.registersToLoad[i].id);
    }

    let filter = {
      //license_id: this.graph.license_id,
      //project_id: this.graph.project_id,
      device_register_ids: this.fetchRegisterIds,
      order: [["timestamp_begin", "ASC"]],
      limit: this.fetchLimitInterval,
      timemode: this.graph.timemode,
      aggregationmode: this.graph.aggregationmode,
      aggregationkind: this.graph.aggregationkind,
      timerange_value: this.graph.timerange_value,
      timerange_type: this.graph.timerange_type,
      date_from: this.fetchDateFrom,
      date_to: this.fetchDateTo
    };

    let latestResponse: any = {};
    let latestFilterDatefrom = null;

    while(this.fetchingData && latestResponse != null && this.chart != null) {
      //console.log("Fetching graph data...");
      if(latestFilterDatefrom && filter.date_from && latestFilterDatefrom == filter.date_from) {
        filter.date_from = moment(filter.date_from).add(1, "second");
      }

      latestFilterDatefrom = filter.date_from;

      try {
        latestResponse = await this.network.sendRequest('/measurements/measurements/get', 'POST', filter, {preferWS: false});
        if(!latestResponse || !latestResponse.measurements) {
          //console.log("Fetch graph data. No More Data.");
          latestResponse = null;
        }
      } catch (e) {
        //console.log("Fetch graph data ERR");
        latestResponse = null;
      }

      if(latestResponse == null) {
        //console.log("Fetch graph data. latestResponse was NULL");
        break;
      }

      let newMeasurements = latestResponse.measurements;

      let nodata = true;
      for (let register_id in newMeasurements) {
        if(nodata && newMeasurements[register_id].length > 0) {
          nodata = false;
        }
        for (let i = 0; i < newMeasurements[register_id].length; i++) {
          this.addMeasurement(register_id, newMeasurements[register_id][i], false);
          filter.date_from = newMeasurements[register_id][i].timestamp_begin;
        }
      }

      if(nodata) {
        this.fetchingData = false;
        latestResponse = null;
      }
    }

    if(this.graph != null) {
      this.limitToDatapoints();
      this.setCurrentGraphData();
      this.subscribeGraph();
    }

    this.fetchingData = false;
  }

  limitToDatapoints() {
    let datapoints = this.graph.datapoints ? Math.min(this.graph.datapoints, AppSettings.DATAPOINTS_MAX) : AppSettings.DATAPOINTS_MAX;

    let measurementsTmp;
    let dataLength;
    let dataInterval;
    let countProcessing;

    for(let i = 0; i < this.measurements.length; i++) {
      measurementsTmp = [];
      dataLength = this.measurements[i].data.length;
      countProcessing = 0;
      dataInterval = Math.max(1, Math.round(dataLength / datapoints));

      //console.log("Register "+i+" ; " + dataLength + " ; Intervall: " + dataInterval);

      for(let j = 0; j < dataLength; j++) {
        if(countProcessing%dataInterval===0 || (j+1) === dataLength) {
          measurementsTmp.push(this.measurements[i].data[j]);
        }

        countProcessing++;
      }

      this.measurements[i].data = measurementsTmp;
    }
  }

  addMeasurement(register_id, measurement, setGraphData = false) {
    //console.log("addMeasurement " + JSON.stringify(measurement));

    if(this.graph.roundvalues != null && !isNaN(this.graph.roundvalues)) {
      this.graph.roundvalues = Math.max(0, this.graph.roundvalues);
      measurement.value = parseFloat(measurement.value).toFixed(this.graph.roundvalues);
    }

    for(let j = 0; j < this.measurements.length; j++) {
      if(this.measurements[j].register_id == register_id) {
        let name = "Name: ";
        let xAxis;

        if(measurement.xAxis) {
          name += measurement.xAxis;
          xAxis = measurement.xAxis;
        } else {
          name += measurement.timestamp_begin;
          xAxis = moment(measurement.timestamp_begin).toDate();
        }

        let pushNewData = true;

        // Add to existing value instead of pushing new data
        if(this.graph.aggregationmode && this.graph.aggregationmode != "none") {
          for(let i = 0; i < this.measurements[j].data.length; i++) {
            if(this.measurements[j].data[i].name == name) {
              this.measurements[j].data[i].value[1] += measurement.value;
              pushNewData = false;
              break;
            }
          }
        }

        if(pushNewData) {
          this.measurements[j].data.push({
            "name": name,
            "value": [
              xAxis,
              measurement.value
            ]
          });
        }
      }
    }

    if(setGraphData) {
      this.setCurrentGraphData();
    }
  }

  subscribeGraph() {
    this.network.subscribe('/graph/measurements/'+this.graph_id, this.dataReceived, function(err) {});
  }

  unsubscribeGraph() {
    this.network.unsubscribe('/graph/measurements/'+this.graph_id);
  }

  dataReceived = (data, flags) => {
    //console.log("graph measurement received " + JSON.stringify(data));

    if(this.componentState != "loaded" || !this.graph) {
      return;
    }

    this.addMeasurement(data.device_register_id, data, true);
  };

  setCurrentGraphData() {
    if(!this.chart) {
      return;
    }

    let newData = [];
    for(let i = 0; i < this.measurements.length; i++) {
      newData.push({data: this.measurements[i].data});
    }

    this.chart.setOption({
      series: newData
    });
  }

  setLegendWidth() {
    this.legend.width = this.chartDom.nativeElement.getBoundingClientRect().width - 130;
  }

  async setupChart() {
    //console.log("this.chartDom " + this.chartDom + " " + this.chartDom.nativeElement);

    this.setLegendWidth();

    this.chart = echarts.init(this.chartDom.nativeElement);
    this.chartOptions = {
      xAxis: this.xAxisSettings,
      yAxis: this.yAxisSettings,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          animation: true,
          label: {
            backgroundColor: '#505765'
          }
        }
      },
      legend: this.legend,
      visualMap: this.visualMap,
      dataZoom: [{
        show: true,
        realtime: true,
        start: 0,
        end: 100
      },
        {
          type: 'inside',
          realtime: true,
          start: 0,
          end: 100
        }
      ],
      toolbox: {
        feature: {
          restore: {
            show: true,
            title: 'zurücksetzen'
          },
          saveAsImage: {
            show: true,
            title: 'Als Bild speichern',
          }
        }
      },
      series: this.measurements
    };

    if (this.chartOptions && typeof this.chartOptions === "object") {
      this.chart.setOption(this.chartOptions, {notMerge: true, lazyUpdate: false, silent: false});
    }

    await this.getMeasurements();

    this.resizeCheckInterval = setInterval(this.checkForResize.bind(this), 250);

    this.update.emit({type: "ready"});
  }

  checkForResize() {
    if(this.containerWidth != this.chartDom.nativeElement.clientWidth) {
      this.containerWidth = this.chartDom.nativeElement.clientWidth;
      this.setLegendWidth();

      if(this.chart) {
        this.chart.setOption({
          legend: this.legend
        });

        setTimeout(() => {
          this.chart.resize();
          this.update.emit({type: "ready"});
        }, 1);
      }
    }
  }

  onMouseEntered() {
    this.showOverlay = true;
  }

  onMouseLeft() {
    this.showOverlay = false;
  }

  resetChart() {
    this.fetchingData = false;
    this.unsubscribeGraph();
    clearInterval(this.resizeCheckInterval);
    this.update.emit({type: "ready"});
  }

  ngOnDestroy() {
    this.resetChart();
    if(this.chart) {
      this.chart.dispose();
      this.chart = null;
    }
  }

}
