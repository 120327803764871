import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {ToasterModule, ToasterService} from 'angular2-toaster';
import { AppRoutingModule } from '../app-routing.module';
import { NgxUploaderModule } from 'ngx-uploader';
import { ColorPickerModule } from 'ngx-color-picker';
import {DragDropModule} from '@angular/cdk/drag-drop';

// Elements
import { PaginationComponent } from './elements/pagination/pagination.component';
import { StatusMessageComponent } from './elements/status_message/status-message.component';
import { ModalComponent } from './elements/modal/modal.component';
import { ToastsComponent } from './elements/toasts/toasts.component';
import { GraphregisterEditComponent } from './elements/graphregister-edit/graphregister-edit.component';

// Pipes
import { KeysPipe} from './pipes/keys.pipe';
import { SafeHtmlPipe} from './pipes/safehtml.pipe';
import { ModulemenuComponent } from './elements/modulemenu/modulemenu.component';
import {RouterModule} from '@angular/router';
import { UsermenuComponent } from './elements/usermenu/usermenu.component';
import { MeasurementsGraphComponent } from './elements/measurements-graph/measurements-graph.component';
import { MeasurementsGrapheditComponent } from './elements/measurements-graphedit/measurements-graphedit.component';
import { MessagesmenuComponent } from './elements/messagesmenu/messagesmenu.component';
import { SpinnerComponent } from './elements/spinner/spinner.component';
import { FilterLicenseComponent } from './elements/filter/filter-license/filter-license.component';
import { MeasurementsGraphPieComponent } from './elements/measurements-graph/measurements-graph-pie/measurements-graph-pie.component';
import { MeasurementsGraphDefaultComponent } from './elements/measurements-graph/measurements-graph-default/measurements-graph-default.component';
import { MeasurementsGraphGaugeComponent } from './elements/measurements-graph/measurements-graph-gauge/measurements-graph-gauge.component';
import { MeasurementsGrapheditInlineComponent } from './elements/measurements-graphedit-inline/measurements-graphedit-inline.component';
import { MeasurementsGraphSankeyComponent } from './elements/measurements-graph/measurements-graph-sankey/measurements-graph-sankey.component';

registerLocaleData(localeDe, 'de-DE');

@NgModule({
    imports: [
        RouterModule.forChild([]),
        HttpClientModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ToasterModule,
        NgxUploaderModule,
        ColorPickerModule,
        DragDropModule
    ],

  providers: [
    ToasterService,
    {provide: LOCALE_ID, useValue: 'en-EN'}
  ],

  declarations: [
    PaginationComponent,
    StatusMessageComponent,
    ModalComponent,
    ToastsComponent,
    KeysPipe,
    SafeHtmlPipe,
    ModulemenuComponent,
    UsermenuComponent,
    MeasurementsGraphComponent,
    MeasurementsGrapheditComponent,
    MessagesmenuComponent,
    SpinnerComponent,
    FilterLicenseComponent,
    MeasurementsGraphPieComponent,
    MeasurementsGraphDefaultComponent,
    MeasurementsGraphDefaultComponent,
    MeasurementsGraphGaugeComponent,
    GraphregisterEditComponent,
    MeasurementsGrapheditInlineComponent,
    MeasurementsGraphSankeyComponent
  ],

  entryComponents: [
    PaginationComponent,
    StatusMessageComponent,
    ModalComponent,
    ToastsComponent
  ],

  exports: [
    CommonModule,
    FormsModule,
    PaginationComponent,
    StatusMessageComponent,
    ModalComponent,
    ToastsComponent,
    FormsModule,
    ReactiveFormsModule,
    KeysPipe,
    SafeHtmlPipe,
    ModulemenuComponent,
    UsermenuComponent,
    MeasurementsGraphComponent,
    MeasurementsGrapheditComponent,
    GraphregisterEditComponent,
    MessagesmenuComponent,
    SpinnerComponent,
    FilterLicenseComponent,
    NgxUploaderModule,
    ColorPickerModule,
    DragDropModule
  ]

})
export class SharedModule { }
