import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import {Router, ActivatedRoute} from '@angular/router';
import {Subject, Observable, Subscription} from 'rxjs';
import { environment } from '../../../environments/environment';

import { GlobalsProvider } from './globals.provider';
import { AppSettings } from '../../app.settings';

declare const require: any;
const Nes = require('@hapi/nes/lib/client');

@Injectable()
export class NetworkProvider {
  private subscriptions: Subscription = new Subscription();
  public client;
  private reconnectInterval: any;

  constructor(private router: Router, private route: ActivatedRoute, private http: HttpClient, private globals:GlobalsProvider) {
    this.init();
  }

  init() {
    this.connectClient();
  }

  connectClient() {
    if(this.client != null) {
        this.disconnectClient();
    }

    this.client = new Nes.Client('ws://'+AppSettings.WS_URL);
    this.client.onError = this.onClientError.bind(this);
    this.client.onConnect = this.onClientConnect.bind(this);
    this.client.onDisconnect = this.onClientDisconnect.bind(this);
    this.client.onUpdate = this.onClientUpdate.bind(this);

    let options = {
        delay:1000,
        maxDelay:10000,
        retries:Infinity,
        auth:{
            headers:{
                authorization:''
            }
        }
    };

    let authorization = window.localStorage.getItem('authorization');

    if(authorization != null) {
        options.auth.headers.authorization = authorization;
    }

    this.client.connect(options, (err: any) => {
        if(err) {
            this.client = null;
            this.globals.setData('connection', null);

            if(!this.reconnectInterval) {
                this.reconnectInterval = setInterval(() => {this.connectClient()}, 1000);
            }

            console.error("socket connect err " + JSON.stringify(err));
        } else {
            console.log("socket connected");
            clearInterval(this.reconnectInterval);
        }
    });
  }

  disconnectClient() {
    if(this.client) {
      this.client.disconnect();
    }
  }

  onClientConnect = () => {
      this.globals.setData('connection', this.client);
      this.globals.setData("toast_message", {title:'Verbunden', content:'Erfolgreich verbunden', type:'success'});
  };

  onClientDisconnect = (willReconnect, log) => {
      console.log("client onDisconnect " + willReconnect);

      this.globals.setData('connection', null);
      this.globals.setData("toast_message", {title:'Verbindung', content:'Verbindung getrennt', type:'info'});
  };

  onClientUpdate = (update) => {
      console.log("onClientUpdate " + update);
  };

  onClientError = (err) => {
      console.log("client err " + err);
  };

  subscribe(path, handler, callback = null) {
      this.client.subscribe(path, handler, function(err) {});
  }

  unsubscribe(path, handler = null, callback = null) {
      this.client.unsubscribe(path, handler, function(err) {});
  }

  sendRequest(path: any, method: string = "POST", data: any = {}, options: any = {}) {
    options['showErrors'] = options['showErrors'] ? options['showErrors'] : true;
    options['showMessages'] = options['showMessages'] ? options['showMessages'] : true;

    if(this.client && options.preferWS) {
      return this.sendWSRequest(path, method, data, options);
    } else {
      return this.sendHttpRequest(path, method, data, options);
    }
  }

  sendWSRequest(path: any, method: string, data: any, options: any) {
    let license_id = this.globals.getData('license_id');
    let dashboard_invite = this.globals.getData('dashboard-invite');
    let myUser = this.globals.getData("user");

    let params: any = {};
    let requestOptions: any = {};

    if(!license_id) {
      license_id = window.localStorage.getItem('license_id');
    }

    if(license_id && myUser && myUser.usertype == "admin") {
      params.license_id = license_id;
    }

    if(dashboard_invite != null) {
      params.dashboard_invite = dashboard_invite;
    }

    let urlParameters = Object.entries(params).map(e => e.join('=')).join('&');

    path = path + "?" + urlParameters;

    requestOptions.path = path;
    requestOptions.method = method;
    requestOptions.payload = data;

    return new Promise((resolve, reject) => {
        return this.client.request(requestOptions).then((res) => {
          data = {
            payload: {body: res.payload},
            headers: null
          };
           data = this.resolveMessageSuccess(data, options.showMessages);
           return resolve(data);
        }).catch((e) => {
          data = {
            payload: {
              headers: null,
              error: e.data,
              status: e.statusCode,
              statusText: e.data ? e.data.reason : null,
            }
          };
          data = this.resolveMessageError(data, options.showErrors);
          return reject(data);
        });
    });
  }

  sendHttpRequest(path: any, method: string, data: any, options: any) {
    let url = AppSettings.PROTOCOL+AppSettings.URL+path;
    let authorization = window.localStorage.getItem('authorization');
    let license_id = this.globals.getData('license_id');
    let dashboard_invite = this.globals.getData('dashboard-invite');
    let myUser = this.globals.getData("user");

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json; charset=utf-8');

    //console.log("authorization " + authorization);

    if(!license_id) {
      license_id = window.localStorage.getItem('license_id');
    }

    if(license_id && myUser && myUser.usertype == "admin") {
      headers = headers.append('license_id', license_id);
    }

    if(authorization != null) {
      headers = headers.append('Authorization', authorization);
    }

    if(dashboard_invite != null) {
      headers = headers.append('dashboard_invite', dashboard_invite);
    }

    if(this.client != null && this.client.id) {
      headers = headers.append('sid', this.client.id);
    }

    return new Promise((resolve, reject) => {

      let requestOptions: any = {
        method: 'get',
        body: null,
        headers: null,
        observe: 'response'
      };

      requestOptions.headers = headers;

      if(options.responseType) {
        requestOptions.responseType = options.responseType;
      }

      switch(method) {
        case "POST":
            requestOptions.method =  'post';
            requestOptions.body = data;
            break;
        case "GET":
            requestOptions.method = 'get';
            break;
        case "PUT":
            requestOptions.method = 'put';
            requestOptions.body = data;
            break;
        case "DELETE":
            requestOptions.method = 'delete';
            break;
      }

      this.http.request(requestOptions.method, url, requestOptions).subscribe(
        (res:any ) => {
          if(res.headers.get('content-type').indexOf('/json') === -1) {
            resolve(res.body);
            return;
          }

          let result = {payload: res, headers: res.headers, statusCode: res.status};
          data = this.resolveMessageSuccess(result, options.showMessages);
          resolve(data);
        },
        (err: any) => {
          if(err.status == 200 && err.message.indexOf() !== -1) {

          }
          let result = {payload: err, headers: err.headers, statusCode: err.status};
          data = this.resolveMessageError(result, options.showErrors);
          reject(data);
        },
        () => {
          //console.log('http finished')
        }
      );
    });
  }

  resolveMessageSuccess(res: any, showMessages: any) {
    let payload = res.payload;
    let body = payload.body;
    let headers = payload.headers;
    let authorization = null;

    if(headers) {
        authorization = headers.get('authorization');
    }

    if(authorization != null) {
        console.log("authorization received " + authorization);
        window.localStorage.setItem('authorization', authorization);
        this.globals.setData('authorization', authorization);
    }

    if(body.user != null) {
      this.globals.saveUser(body.user);
    } else {

    }

    if(showMessages) {
        if(body.message) {
            this.globals.setData('status_message', {type:'success', message: body.message});
        }
    }

     return body;
  }

    resolveMessageError(res: any, showErrors: boolean = true) {
        let error = res.payload.error;
        let headers = res.payload.headers;
        let status = res.payload.status;
        let statusText = res.payload.statusText ? res.payload.statusText : "Es ist ein Fehler aufgetreten";
        let errorMessage = error&&error.message ? error.message : statusText;

        if(status == 901) { // License error
          //this.router.navigate(['/admin/licenses']);
          this.globals.setData("toast_message", {title: '', content: statusText, type: 'error'});
        } else if(status == 422) {
          this.globals.setData("toast_message", {title: '', content: statusText, type: 'error'});
        } else if(status == 401) {
            window.localStorage.setItem('authorization', '');
            this.globals.setData('authorization', '');
            this.globals.setData('user', null);

            /*
            if(this.router.url.indexOf('/auth/') === -1) {
                this.globals.setData("toast_message", {title: '', content: 'Keine Rechte, um diese Seite aufzurufen', type: 'error'});
                this.router.navigate(['/auth/login']);
            }
             */
        } else if(status == 404) {
            this.globals.setData("toast_message", {title: '', content: 'Seite konnte nicht gefunden werden', type: 'error'});
        } else {
            if(showErrors) {
                //this.globals.setData("toast_message", {title: '', content: errorMessage, type: 'error'});
            } else {
                //this.globals.setData("toast_message", {title: '', content: 'Verbindung fehlgeschlagen', type: 'error'});
            }
        }

        return res.payload;
    }

}
