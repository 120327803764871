import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GlobalsProvider } from '../../providers/globals.provider';

@Component({
  selector: 'app-modulemenu',
  templateUrl: './modulemenu.component.html',
  styleUrls: ['./modulemenu.component.css']
})
export class ModulemenuComponent implements OnInit {

  private subscriptions: Subscription = new Subscription();
  public user: any;
  public currentMenu: any = 'admin';

  public hasDashboard: boolean = false;
  public hasAdmin: boolean = false;
  public hasMeasurements: boolean = false;
  public hasEmergencylists: boolean = false;

  constructor(private router:Router, private activatedRoute: ActivatedRoute, private globals: GlobalsProvider) {
    this.user = this.globals.getData('user');

    this.subscriptions.add(this.globals.addObservable('user').subscribe(
      data => {
        this.user = data;
        this.checkRights();
      }
    ));

    this.subscriptions.add(this.globals.addObservable('modulemenu').subscribe(
      data => {
        this.currentMenu = data;
      }
    ));

    this.checkRights();
  }

  ngOnInit() {
  }

  checkRights() {
    this.hasDashboard = this.globals.hasAnyScopeOf('dashboard');
    this.hasAdmin = this.globals.hasAnyScopeOf('admin');
    this.hasMeasurements = this.globals.hasAnyScopeOf('measurements');
    this.hasEmergencylists = this.globals.hasAnyScopeOf('emergencylists');
  }

}
