import { Component, Input, ElementRef } from '@angular/core';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
})
export class ModalComponent {

    public visible = false;
    public visibleAnimate = false;

    public noheader:Boolean = false;
    public nofooter:Boolean = false;
    public closeonbackdrop:Boolean = false;
    public containerclass:string = "modal-md";

    public show(options:any = null): void {
        if(options) {
            if(options.noheader) {
                this.noheader = true;
            }
            if(options.nofooter) {
                this.nofooter = true;
            }
            if(options.containerclass) {
                this.containerclass = options.containerclass;
            }
            if(options.closeonbackdrop) {
                this.closeonbackdrop = options.closeonbackdrop;
            }
        }

        this.visible = true;
        setTimeout(() => this.visibleAnimate = true, 100);
    }

    public hide(): void {
        this.visibleAnimate = false;
        setTimeout(() => this.visible = false, 300);
    }

    public onContainerClicked(event: MouseEvent): void {
        if(this.closeonbackdrop) {
            if ((<HTMLElement>event.target).classList.contains('modal')) {
                this.hide();
            }
        }
    }

}
