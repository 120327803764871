import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';

// Providers
import { NetworkProvider } from './shared/providers/network.provider';
import { GlobalsProvider } from './shared/providers/globals.provider';
import { AdminGuard } from './admin/admin-guard.service';
import { DashboardGuard } from './dashboard/dashboard-guard.service';
import { MeasurementsGuard } from './measurements/measurements-guard.service';
import { EmergencylistsGuard } from './emergencylists/emergencylists-guard.service';
import {SharedModule} from './shared/shared.module';

registerLocaleData(localeDe, 'de-DE');

@NgModule({
  declarations: [
    AppComponent
  ],

  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule
  ],

  providers: [
    NetworkProvider,
    GlobalsProvider,
    Title,
    AdminGuard,
    DashboardGuard,
    MeasurementsGuard,
    EmergencylistsGuard,
    {provide: LOCALE_ID, useValue: 'en-EN'}
  ],

  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
