import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import {ToasterContainerComponent, ToasterService, ToasterConfig} from 'angular2-toaster';

import { GlobalsProvider } from '../../providers/globals.provider';

@Component({
  templateUrl: './toasts.component.html',
  selector: 'toasts'
})
export class ToastsComponent {
  private subscriptions: Subscription = new Subscription();

  public toasterconfig : ToasterConfig = new ToasterConfig({
                    showCloseButton: false,
                    tapToDismiss: true,
                    timeout: 2300,
                    animation: 'flyRight',
                    limit: 10,
                    newestOnTop: true,
                    mouseoverTimerStop: true,
                    positionClass: 'toast-bottom-right',
                    defaultTypeClass: 'uitoast',
                    typeClasses: {
                        error: 'uitoast-error',
                        info: 'uitoast-info',
                        wait: 'uitoast-wait',
                        success: 'uitoast-success',
                        warning: 'uitoast-warning'
                    },

                    messageClass: 'uitoast-message',
                    titleClass: 'uitoast-title'
                });

  constructor(private globals:GlobalsProvider, public toaster:ToasterService) {
      this.subscriptions.add(this.globals.addObservable('toast_message').subscribe(
          data => {
            if(data != null) {
                this.toaster.pop(data.type, data.title, data.content);
                this.globals.setData('toast_message', null);
            }
          }
      ));
  }

  ngAfterViewInit() {
    let data = this.globals.getData('toast_message');
    if(data != null) {
      this.toaster.pop(data.type, data.title, data.content);
      this.globals.setData('toast_message', null);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
