import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GlobalsProvider } from '../../providers/globals.provider';

@Component({
  selector: 'app-usermenu',
  templateUrl: './usermenu.component.html',
  styleUrls: ['./usermenu.component.css']
})
export class UsermenuComponent implements OnInit {

  private subscriptions: Subscription = new Subscription();
  public myUser: any;

  constructor(private globals:GlobalsProvider) {
    this.myUser = this.globals.getData("user");

    this.subscriptions.add(this.globals.addObservable('user').subscribe(
      data => {
        this.myUser = data;
      }
    ));
  }

  ngOnInit() {

  }

  onLogout() {
    this.globals.logout();
  }
}
