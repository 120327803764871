import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit {
  @Input('margin') margin: any = 100;
  @Input('width') width: any = 75;
  @Input('height') height: any = 60;

  constructor() { }

  ngOnInit() {
  }

}
