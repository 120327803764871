import { Injectable } from '@angular/core';
import {
  CanActivate, Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Route
}  from '@angular/router';

import { GlobalsProvider } from '../shared/providers/globals.provider';

@Injectable()
export class EmergencylistsGuard implements CanActivate {

  constructor(private globals: GlobalsProvider, private router: Router) {}

    canLoad(route: Route): boolean {
      if(this.checkIfPasswordInit()) {
        return false;
      }

      if(!route.data || !route.data["scope"]) {
        return true;
      }

      if(this.globals.checkUserScope(route.data["scope"])) {
        return true;
      } else {
        this.globals.logout();
        return false;
      }
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      if(this.checkIfPasswordInit()) {
        return false;
      }

        if(!route.data || !route.data["scope"]) {
            return true;
        }

      if(this.globals.checkUserScope(route.data["scope"])) {
        return true;
      } else {
        this.globals.logout();
        return false;
      }
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    checkIfPasswordInit() {
      let user: any = this.globals.getData("user");

      if(user && user.password_init) {
        this.router.navigate(["/auth/password-init"]);
        return true;
      }

      return false;
    }
}
