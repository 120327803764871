import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminGuard } from './admin/admin-guard.service';
import {DashboardGuard} from './dashboard/dashboard-guard.service';
import {MeasurementsGuard} from './measurements/measurements-guard.service';
import { EmergencylistsGuard } from './emergencylists/emergencylists-guard.service';

const routes: Routes = [

  { path: '', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canLoad: [AdminGuard]},
  { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), canLoad: [DashboardGuard]},
  { path: 'measurements', loadChildren: () => import('./measurements/measurements.module').then(m => m.MeasurementsModule), canLoad: [MeasurementsGuard]},
  { path: 'emergencylists', loadChildren: () => import('./emergencylists/emergencylists.module').then(m => m.EmergencylistsModule), canLoad: [MeasurementsGuard]}
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})

export class AppRoutingModule {}
