import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { NetworkProvider } from '../../../../shared/providers/network.provider';
import { GlobalsProvider } from 'src/app/shared/providers/globals.provider';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-filter-license',
  templateUrl: './filter-license.component.html',
  styleUrls: ['./filter-license.component.css']
})
export class FilterLicenseComponent implements OnInit {
  @Output() update: EventEmitter<any> = new EventEmitter<any>();
  private subscriptions: Subscription = new Subscription();
  public licenses: any;
  public myUser: any;
  public license_id: any;

  constructor(private globals:GlobalsProvider, public network: NetworkProvider) {
    this.myUser = this.globals.getData("user");
    this.subscriptions.add(this.globals.addObservable('user').subscribe(
      data => {
        this.myUser = data;
        this.loadOptions();
      }
    ));
  }

  ngOnInit() {
    // Only admins are allowed to switch licenses
    if(!this.myUser || this.myUser.usertype != "admin") {
      return;
    }

    this.loadOptions();
  }

  loadOptions() {
    // Only admins are allowed to switch licenses
    if(!this.myUser || this.myUser.usertype != "admin") {
      return;
    }
    this.network.sendRequest('/licenses/list', 'POST', {}).then((data:any) => {
      this.licenses = data.licenses;
      this.checkActiveLicense();
    }).catch((e: any) => {
      console.log(e);
    });
  }

  checkActiveLicense() {
    if(this.license_id) {
      return;
    }

    this.license_id = this.globals.getData('license_id');
    if(!this.license_id) {
      this.license_id = window.localStorage.getItem('license_id');
      if(!this.license_id) {
        this.onLicenseChange('all');
      } else {
        this.onLicenseChange(this.license_id);
      }
    }
  }

  onLicenseChange(license_id: any) {
    this.license_id = license_id;
    this.update.emit(this.license_id);
    this.globals.setData('license_id', this.license_id);
    window.localStorage.setItem('license_id', this.license_id);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
