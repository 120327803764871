import { Component, Input, Output, EventEmitter } from '@angular/core';

declare let $:any;

@Component({
  templateUrl: './pagination.component.html',
  selector: 'pagination',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent {
    @Input() currentPage: number;
    @Input() itemsPerPage: number;
    @Input() totalItems: number;

    @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() itemsPerPageChange: EventEmitter<number> = new EventEmitter<number>();

    public pageRangeLimit:number = 2;

  constructor() {
  }

  ngOnInit() {
  }

  onItemsPerPageChanged(value: number) {
    this.itemsPerPageChange.emit(value);
  }

  setTooltips() {
    setTimeout(function() {
      $('[data-toggle="tooltip"]').tooltip();
    }, 0);
  }

  lastPage() {
      this.setCurrent(this.getLastPage());
  }

  firstPage() {
      this.setCurrent(1);
  }

  previous() {
      this.setCurrent(this.getCurrent() - 1);
  }

  next() {
      this.setCurrent(this.getCurrent() + 1);
  }

  isFirstPage(): boolean {
      return this.getCurrent() === 1;
  }

  isLastPage(): boolean {
      return this.getLastPage() === this.getCurrent();
  }

  setCurrent(page: number) {
      this.currentPage = page;
      this.pageChange.emit(page);

      //this.setTooltips();
  }

  getCurrent(): number {
      return this.getCurrentPage();
  }

  getLastPage(): number {
      if (!this.totalItems || this.totalItems < 1) {
          return 1;
      }
      return Math.ceil(this.totalItems / this.itemsPerPage);
  }

  public getCurrentPage(): number {
      return this.currentPage;
  }

  createPageRangeBefore(){
      var items = [];
      for(var i = this.pageRangeLimitBefore(); i <= this.getCurrent()-1; i++){
         items.push(i);
      }
      return items;
  }

  createPageRangeAfter(){
      var items = [];
      for(var i = this.getCurrent()+1; i <= this.pageRangeLimitAfter(); i++){
         items.push(i);
      }
      return items;
  }

  pageRangeLimitAfter() {
    return Math.min((this.getCurrent()+this.pageRangeLimit), this.getLastPage());
  }

  pageRangeLimitBefore() {
    return Math.max(this.getCurrent()-this.pageRangeLimit, 1);
  }


}
